/**
 * -----------------------------------------------------------------------------
 * Copyright (C) Ubiwhere, LDA
 * 2012-2018
 *
 *
 * The reproduction, transmission or use of this document or its contents is not
 * permitted without express written authorization. All rights, including rights
 * created by patent grant or registration of a utility model or design, are
 * reserved. Modifications made to this document are restricted to authorized
 * personnel only. Technical specifications and features are binding only when
 * specifically and expressly agreed upon in a written contract.
 * -----------------------------------------------------------------------------
 *
 * Config.js
 * Where we we set all the configurations and constants to be used in the application
 */

import 'interceptors'

// ! Do not remove the env comments they are used for building the correct environemnt
// env
export * from 'environments/guimaraes/production'
// env

// Core Constants
export const VERSION =
  process.env.VERSION && process.env.VERSION.replace(/"/g, '')
export const BUILD_DATE = process.env.BUILD_DATE
export const AUTH_TOKEN_TYPE = 'JWT'
// Field Constants

export const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm'
export const API_DATE_FORMAT = 'YYYY-MM-DD'
export const DATE_FORMAT = 'DD-MM-YYYY'

// Other Contants

export const AUTOMATIC_EDIT_PERMISSIONS = {
  gateway: ['geometry', 'name'],
  sensor: ['geometry', 'name'],
  spot: ['geometry', 'spotType'],
  repeater: ['geometry', 'name'],
  panel: ['geometry', 'name'],
  area: [
    'geometry',
    'name',
    'capacityMargin',
    'capacity',
    'description',
    'address'
  ]
}
